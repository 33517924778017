import React from 'react';
const Norbio = () => {
  return (
    <div id="nor" className="flex center column">
      <p>Den norske mezzosopranen Kari Dahl Nielsen fra Karmøy er ansatt som operasolist ved Den kongelige danske opera i København. Hun er utdannet hos elitepedagog Susanna Eken ved Operaakademiet i København, Det kongelige danske musikkonservatorium, Griegakademiet i Bergen og Hochschule für Musik Karlsruhe. Hun ble tildelt talentprisen i Årets Reumert 2021, dansk scenekunsts største prisutdeling.</p>
      <p>I 2023 debuterer Kari ved Operaen i Oslo i rollen som Dorabella i Mozarts Così fan tutte under ledelse av Tobias Ringborg.</p>
      <p>I sesongen 22/23 i København synger hun rollene Tisbe (La Cenerentola) under ledelse av Steven Moore og Thomas Bagwell, Mercédès (Carmen) under ledelse av Hossein Pishkar, og Dorabella (Così fan Tutte) under ledelse av Kristiina Poska og Nick Fletcher.</p>
      <p>Kari hadde sin operadebut som Cherubino (Le nozze di Figaro) under ledelse av Alexis Kossenko og Concerto Copenhagen ved Det kongelige teater (DKT) i 2018, og høstet gode kritikker i både dansk og internasjonal presse. På DKT har hun også sunget roller som Zerlina (Don Giovanni), Siébel (Faust), 2. Dame (Die Zauberflöte), Emilia (Otello), Maddalena (Rigoletto), Blumenmädchen (Parsifal) og Agnes (Intet). Sistnevnte vant Reumert-prisen for årets opera i 2021.</p>
      <p>Hun har jobbet med internasjonale dirigenter som Paolo Carignani, Pier Giorgio Morandi, Alexander Vedernikov, Julia Jones, Marie Jacquot, Lars Ulrik Mortensen og Nikolaj Szeps-Znaider.</p>
      <p>Kari har i tillegg en særlig interesse for Lieder, og har fordypet seg spesielt i Edvard Griegs liv og virke.</p>
    </div>
  )
}
export default Norbio;
