import React from 'react';

const Engbio = (props) => {
  return (
    <div id="en" className="flex center column">
      <p>The Norwegian lyric mezzo soprano Kari Dahl Nielsen is permanent member of the soloist ensemble at The Royal Danish Opera (RDO) in Copenhagen. She trained at the Royal Opera Academy with elite pedagogue Susanna Eken, the Royal Danish Academy of Music in Copenhagen, the Grieg Academy in Bergen and Hochschule für Musik in Karlsruhe. Kari was awarded the Reumert talent prize in 2021.</p>
      <p>In 2023 Kari will make her debut at the Norwegian National Opera, featuring Dorabella in Mozart's Così fan tutte with conductor Tobias Ringborg.</p>
      <p>Kari will perform the roles Tisbe (La Cenerentola) with conductors Steven Moore and Thomas Bagwell, Mercédès (Carmen) with conductor Hossein Pishkar, and Dorabella (Così fan tutte) with conductors Kristiina Poska and Nick Fletcher in season 22/23 at RDO.</p>
      <p>Kari made her opera debut as Cherubino (Le nozze di Figaro) with Concerto Copenhagen conducted by Alexis Kossenko at RDO in 2018, receiving excellent reviews in Danish and International press. At RDO she has performed Zerlina (Don Giovanni), Siébel (Faust), 2. Dame (Die Zauberflöte), Emilia (Otello), Maddalena (Rigoletto), Blumenmädchen (Parsifal) and Agnes (Intet), amongst other roles. Intet was awarded the Reumert for Opera of the Year in 2021.</p>
      <p>She has worked with internationally acclaimed conductors such as Paolo Carignani, Pier Giorgio Morandi, Alexander Vedernikov, Julia Jones, Marie Jacquot, Lars Ulrik Mortensen and Nikolaj Szeps-Znaider.</p>
      <p>In addition, Kari has an extensive interest in the Lied repertoire, with a specialization in the life and music of Edvard Grieg.</p>
    </div>
  )
}
export default Engbio;
